<template>
  <div>
    <div class="wapper">
      <div>
        <div class="lineTitle">同站线路</div>
        <div class="line_list">
          <div class="part" v-for="item in lineSiteInfo" :key="item.index">
            <div class="site">
              <span>{{ item.name }}</span>
              <span class="sitedistance">{{ formatDistance(item.distance) }}</span>
              <!--
              <span
                class="near_the_site_next"
                @click="sameLine(item)"
                >&gt;
              </span>
              -->
            </div>
            <div
              class="site_line_item"
              v-for="lineItem in item.lines"
              :key="lineItem.index"
              @click="lineDetails(item, lineItem)"
            >
              <div class="tit">
                <span class="name">{{ lineItem.name }}</span>
                <!-- <span class="distance">12分钟/班</span> -->
              </div>
              <div class="end_site">
                <span>开往：{{ lineItem.toStation }}</span>
                <!-- <span style="float: right">未开通实时信息</span> -->
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="column">
          <router-link to="/">
            <div class="column_item">
              <img src="../../assets/line/bus.png" /> <br />乘车
            </div>
          </router-link>
        </div>
        -->
        <div class="sm_noneTic" v-bind:class="{ toggle: isShow }">
          <img src="../../assets/line/icon-none.png" alt="无数据" />
          <strong>加载数据中...</strong>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { connectBusHub, getBusLinesByStationId } from '../../signalR/busHub'

export default {
  data() {
    return {
        isShow: false,
        lineSiteInfo: []
    }
  },
  created: function() {
    this.busStationId = Number(this.$route.query.siteId)
  },
  mounted: function () {
    this.getStations()
  },
  methods: {
    formatDistance (distance) {
      if (distance === null) {
        return ''
      }

      if (distance > 1000) {
        return (distance / 1000).ToFixed(1) + '公里'
      } else if (distance < 20) {
        return '附近'
      } else if (distance < 100) {
        return '百米内'
      } else {
        return distance.ToFixed(1) + '米'
      }
    },
    getStations () {
      connectBusHub().then((busHub) => {
        getBusLinesByStationId(busHub, this.busStationId).then((stations) => {
          if(stations.length) {
            this.isShow = true
            this.lineSiteInfo = stations
            console.log(stations)
          }
        }).catch(err => {
          console.log(err)
        })
      }).catch(err => {
        console.log(err)
      })
    },

    lineDetails: function (item, lineItem) {
      // this.$store.commit('cacheLineInfo', lineItem.lineInfo);
      this.$router.push({
        path: "/txMap",
        query: {
          busLineId: lineItem.id,
          direction: lineItem.direction,
          siteId: item.id
          // siteName: item.name,
        }
      })
    },

    sameLine: function (item) {
      this.$router.push({
        path: "/sameLine",
        query: {
          siteId: item.id
          // siteName: item.name,
        }
      })
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
/*新搜索框*/
.toggle {
    display: none;
}
.pop_list_title {
    width: 100%;
    height: 0.88rem;
    padding: 0.14rem 0.2rem;
    background: #36a3f9;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
}

.pop_list_title .closespan {
    float: right;
    display: block;
    width: 0.9rem;
    height: 0.6rem;
    line-height: 0.6rem;
    font-size: 0.32rem;
    color: #fff;
}

.pop_list_title .input_area {
    float: left;
    width: 70%;
    padding: 0.12rem;
    height: 0.6rem;
    background: #5db4f9;
    margin-left: 0.1rem;
    border-radius: 0.16rem;
}

.pop_list_title .input_area input::-webkit-input-placeholder {
    color: #fff;
}

.pop_list_title .input_area input {
    font-size: 0.28rem;
    color: #fff;
    display: block;
    width: 90%;
    padding-left: 0.52rem;
    height: 0.36rem;
    line-height: 0.36rem;
    background: url(../../assets/line/search2.png) no-repeat left center;
    background-size: 0.36rem 0.36rem;
    border: none;
}
.lineTitle {
    font-size: 0.4rem;
    display: flex;
    align-items: flex-end;
    padding: 0.3rem 0;
}

.line_list .part {
    background: #fff;
    border-radius: 8px;
    color: #292a2c;
    font-size: 0.32rem;
    // height: 2.2rem;
    width: 100%;
    margin-bottom: 0.2rem;
    border-bottom: 1px solid #ebeef5;
    box-shadow: 0px 2px 2px -1px #c0c4cc;
    padding: 0.3rem;
    text-align: left;
}
.line_list .site {
    padding-left: 0.52rem;
    margin-bottom: 0.2rem;
    // background: url(../../assets/line/position.png) left no-repeat;
    background: url(../../assets/line/car2.png) left no-repeat;
    background-size: 0.36rem;
}
.line_list .site_line_item {
    padding: 0.3rem 0;
    border-bottom: 1px solid #e4e7ed;
    &:last-child {
        border-bottom: none;
    }
}
.line_list .tit {
    height: 0.42rem;
    margin-bottom: 0.2rem;
}
.line_list .name {
    float: left;
    border-radius: 4px;
    border: 1px solid #ebeef5;
    border-bottom: 2px solid #409eff;
    border-bottom: 2px solid #3777FF;
    padding: 0 3px;
    // background: url(../../assets/line/car.png) left no-repeat;
    // background-size: 0.36rem 0.36rem;
}
.line_list .distance {
    float: right;
    color: #292a2c;
    // margin-right: 0.35rem;
}
.line_list .sitedistance {
    color: #9a9da0;
    font-size: 0.28rem;
    margin-left: 15px;
}
.line_list .near_the_site_next {
    float: right;
    color: #9a9da0;
}
.line_list .distance strong {
    font-size: 0.28rem;
    color: #292a2c;
    margin: 0 0.2rem;
}
.line_list .end_site {
    // padding-left: 0.56rem;
    color: #9a9da0;
    font-size: 0.28rem;
}
</style>
